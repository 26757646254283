import React, { useState, useEffect } from "react";
import { SelectInputProps } from "../../models/props/input.props";
import makeStyle from "../../style/makeStyle";
import ErrorList from "../atoms/ErrorList";
import useCountryPhoneCodes from "../../hooks/useCountryPhoneCode";
import CountryPhoneCodeType from "../../models/types/api/country-phone-code.type";
import "flag-icon-css/css/flag-icons.min.css";
import { useTranslation } from "react-i18next";

interface PhoneCountryCodeSelectorProps<V extends string | number> extends SelectInputProps<V> {
	name: string;
	inputValue?: string;
	onInputChange?: React.ChangeEventHandler<HTMLInputElement>;
	onSelectedValueChange?: (value: string, code: string) => void;
}

export default function PhoneCountryCodeSelector<V extends string | number>(
	props: PhoneCountryCodeSelectorProps<V>
): JSX.Element {
	const {
		countryPhoneCodes, isLoading, init,
	} = useCountryPhoneCodes();
	const [ dropdownOpen, setDropdownOpen ] = useState(false);
	const [ selectedCode, setSelectedCode ] = useState<CountryPhoneCodeType | null>(null);
	const [ inputValueWithoutCode, setInputValueWithoutCode ] = useState(props.inputValue ?? "");
	const [ validationError, setValidationError ] = useState<string | null>(null);
	const { t } = useTranslation("translation");

	useEffect(() => {
		init();
	}, []);

	useEffect(() => {
		if (!isLoading && countryPhoneCodes.length > 0) {
			const defaultCountryCode = props.inputValue
				? countryPhoneCodes.find(code => props.inputValue!.startsWith(code.code))
				: countryPhoneCodes.find(code => code.isoCode === "NL");

			if (defaultCountryCode) {
				setSelectedCode(defaultCountryCode);
				const phoneNumber = props.inputValue ? props.inputValue.replace(defaultCountryCode.code, "").trim() : "";
				setInputValueWithoutCode(phoneNumber);
			} else {
				setSelectedCode(countryPhoneCodes[0]);
			}
		}
	}, [ isLoading, countryPhoneCodes ]);
	const inputStyle = makeStyle(
		"input",
		[props.errors || validationError ? "border-error" : "border-slate-300"]
	);
	const containerStyle = makeStyle("flex flex-col", [props.className]);
	const rowStyle = makeStyle("flex items-center space-x-2", []);

	const toggleDropdown = (): void => setDropdownOpen(!dropdownOpen);
	const handleSelect = (code: CountryPhoneCodeType): void => {
		setSelectedCode(code);
		setDropdownOpen(false);
		setValidationError(null);
		// Update the parent component with the new selected value
		const fullValue = `${code.code}${inputValueWithoutCode}`;
		props.onSelectedValueChange?.(fullValue, code.code);
	};

	const getFlagClass = (isoCode: string): string => {
		return `flag-icon flag-icon-${isoCode.toLowerCase()}`;
	};

	const validateAndSetInputValue = (): void => {
		if (selectedCode) {
			const fullValue = `${selectedCode.code}${inputValueWithoutCode}`;
			setValidationError(null);
			props.onSelectedValueChange?.(fullValue, selectedCode.code);
		}
	};

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const phoneNumber = event.target.value;
		setInputValueWithoutCode(phoneNumber);
		props.onInputChange?.(event);
		// Update the parent component with the current input value
		if (selectedCode) {
			const fullValue = `${selectedCode.code}${phoneNumber}`;
			props.onSelectedValueChange?.(fullValue, selectedCode.code);
		}
	};

	const handleInputBlur = (): void => {
		// validateAndSetInputValue();
	};

	if (isLoading) {
		return <div>Loading...</div>; // or a loader component
	}

	return props.visible || props.visible === undefined || props.visible === null ? (
		<div className={containerStyle}>
			<label>
				{props.required ? `${props.label} *` : `${props.label}`}
			</label>

			<div className={rowStyle}>
				<div className="relative w-full">
					<button
						id="dropdown-phone-button"
						onClick={toggleDropdown}
						className={`flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm text-center border-b border-gray-300 ${props.errors || validationError ? "border-error" : "border-slate-300"} truncate w-full`}
						type="button"
					>
						{!!selectedCode && (
							<>
								<span className={getFlagClass(selectedCode.isoCode)} style={{ fontSize: "1rem", marginRight: "0.5rem" }} />

								(
								{selectedCode.code}
								)
							</>
						)}

						<svg
							className="w-2.5 h-2.5 ml-2.5"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 10 6"
						>
							<path
								stroke="currentColor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="m1 1 4 4 4-4"
							/>
						</svg>
					</button>

					{!!dropdownOpen && (
						<div
							id="dropdown-phone"
							className={`absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow max-h-60 overflow-y-auto border ${props.errors || validationError ? "border-error" : "border-slate-300"}`}
							style={{ width: "100%" }}
						>
							<ul
								className="py-2 text-sm text-gray-700 list-none"
								aria-labelledby="dropdown-phone-button"
							>
								{countryPhoneCodes.map(countryCode => (
									<li key={countryCode.isoCode}>
										<button
											type="button"
											className="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 truncate"
											role="menuitem"
											onClick={(): void => handleSelect(countryCode)}
										>
											<span className={`flag-icon ${getFlagClass(countryCode.isoCode)} mr-2`} style={{ fontSize: "1rem" }} />

											<span className="inline-flex items-center truncate">
												{countryCode.country}

												{" "}

												(
												{countryCode.code}
												)
											</span>
										</button>
									</li>
								))}
							</ul>
						</div>
					)}
				</div>

				<input
					name={props.name}
					type="text"
					value={inputValueWithoutCode}
					onChange={handleInputChange}
					onBlur={handleInputBlur}
					className={inputStyle}
					placeholder={props.placeholder ?? "0712345678"}
					style={{ width: "100%" }}
					maxLength={selectedCode?.maxLength}
				/>
			</div>

			{!!validationError && (
				<div className="text-error">
					{validationError}
				</div>
			)}

			{!!props.errors && Array.isArray(props.errors) && <ErrorList errors={props.errors} />}
		</div>
	) : (
		<div />
	);
}
